@font-face {
  font-family: "DaxlinePro";
  src: local("DaxlineProRegular"),
   url("./fonts/DaxlinePro/DaxlinePro-Regular.ttf") format("truetype");
  font-weight: normal;
  }

@font-face {
  font-family: "DaxlinePro";
  src: local("DaxlineProBold"),
    url("./fonts/DaxlinePro/DaxlinePro-Bold.ttf") format("truetype");
  font-weight: bold;
}
    
body {
    margin: 0;
    font-family: 'DaxlinePro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* unvisited link */
a:link {
    color: #e13000;
  }
  
  /* visited link */
  a:visited {
    color: #f76100;
  }
  
  /* mouse over link */
  a:hover {
    color: #f76100;
  }
  
  /* selected link */
  a:active {
    color: #f76100;
  }